import '@styles';
import { MDCSelect } from '@material/select';
import { TsxMdcComponent } from '@models';
import { getCountryCodesAllowedList } from './country-codes.util';
const populateCountryCodeList = (items) => {
    const ul = document.getElementById('country-code-list');
    items.forEach((item) => {
        const li = document.createElement('li');
        li.setAttribute('aria-selected', 'false');
        li.setAttribute('class', 'mdc-deprecated-list-item');
        li.setAttribute('data-value', '+' + item.countryCode);
        li.setAttribute('data-iso3', item.iso3);
        li.setAttribute('role', 'option');
        // create the span
        const span = document.createElement('span');
        span.setAttribute('class', 'mdc-deprecated-list-item__ripple');
        // create the span
        const span2 = document.createElement('span');
        span2.setAttribute('class', 'mdc-deprecated-list-item__text');
        span2.innerHTML =
            '<span class="country-code-list-item__code">+' +
                item.countryCode +
                '</span>' +
                item.country;
        // Country Span
        const spanCountry = document.createElement('span');
        spanCountry.setAttribute('class', 'mdc-deprecated-list-item__text hidden');
        spanCountry.innerHTML = item.country;
        // append the spans to the li
        li.appendChild(span);
        li.appendChild(spanCountry);
        li.appendChild(span2);
        // append the li to the ul
        if (ul)
            ul.appendChild(li);
    });
};
(() => {
    const thisWindow = window;
    thisWindow.tsxMdcComponentsInitCCSelects = () => {
        const allowedList = getCountryCodesAllowedList();
        populateCountryCodeList(allowedList);
        const selects = new TsxMdcComponent('.mdc-select--country-code', MDCSelect);
        selects.components.forEach((select) => {
            const cmp = select.component;
            const input = select.nativeElement.querySelector('input[name="country-code"]');
            const selectedText = select.nativeElement.querySelector('#selected-text');
            selectedText.innerText = '+1';
            if (input) {
                input.setAttribute('value', '+1');
            }
            select.nativeElement
                .querySelector('li[data-iso3="USA"]')
                .setAttribute('mdc-deprecated-list-item--selected', 'true');
            cmp.listen('MDCSelect:change', (e) => {
                const countryCode = '+' + allowedList[e.detail.index].countryCode;
                selectedText.innerText = countryCode;
                if (input) {
                    input.setAttribute('value', countryCode);
                }
            });
        });
        return selects;
    };
    thisWindow.tsxMdcComponentsInitCCSelects();
})();
