export const COUNTRY_CODES = [
    {
        country: 'Afghanistan',
        iso2: 'AF',
        iso3: 'AFG',
        countryCode: '93',
    },
    {
        country: 'Albania',
        iso2: 'AL',
        iso3: 'ALB',
        countryCode: '355',
    },
    {
        country: 'Algeria',
        iso2: 'DZ',
        iso3: 'DZA',
        countryCode: '213',
    },
    {
        country: 'American Samoa',
        iso2: 'AS',
        iso3: 'ASM',
        countryCode: '1',
    },
    {
        country: 'Andorra',
        iso2: 'AD',
        iso3: 'AND',
        countryCode: '376',
    },
    {
        country: 'Angola',
        iso2: 'AO',
        iso3: 'AGO',
        countryCode: '244',
    },
    {
        country: 'Anguilla',
        iso2: 'AI',
        iso3: 'AIA',
        countryCode: '1',
    },
    {
        country: 'Antarctica',
        iso2: 'AQ',
        iso3: 'ATA',
        countryCode: '672',
    },
    {
        country: 'Antigua and Barbuda',
        iso2: 'AG',
        iso3: 'ATG',
        countryCode: '1',
    },
    {
        country: 'Argentina',
        iso2: 'AR',
        iso3: 'ARG',
        countryCode: '54',
    },
    {
        country: 'Armenia',
        iso2: 'AM',
        iso3: 'ARM',
        countryCode: '374',
    },
    {
        country: 'Aruba',
        iso2: 'AW',
        iso3: 'ABW',
        countryCode: '297',
    },
    {
        country: 'Ascension Island',
        iso2: 'AC',
        iso3: 'ASC',
        countryCode: '247',
    },
    {
        country: 'Australia',
        iso2: 'AU',
        iso3: 'AUS',
        countryCode: '61',
    },
    {
        country: 'Austria',
        iso2: 'AT',
        iso3: 'AUT',
        countryCode: '43',
    },
    {
        country: 'Azerbaijan',
        iso2: 'AZ',
        iso3: 'AZE',
        countryCode: '994',
    },
    {
        country: 'Bahamas',
        iso2: 'BS',
        iso3: 'BHS',
        countryCode: '1',
    },
    {
        country: 'Bahrain',
        iso2: 'BH',
        iso3: 'BHR',
        countryCode: '973',
    },
    {
        country: 'Bangladesh',
        iso2: 'BD',
        iso3: 'BGD',
        countryCode: '880',
    },
    {
        country: 'Barbados',
        iso2: 'BB',
        iso3: 'BRB',
        countryCode: '1',
    },
    {
        country: 'Belarus',
        iso2: 'BY',
        iso3: 'BLR',
        countryCode: '375',
    },
    {
        country: 'Belgium',
        iso2: 'BE',
        iso3: 'BEL',
        countryCode: '32',
    },
    {
        country: 'Belize',
        iso2: 'BZ',
        iso3: 'BLZ',
        countryCode: '501',
    },
    {
        country: 'Benin',
        iso2: 'BJ',
        iso3: 'BEN',
        countryCode: '229',
    },
    {
        country: 'Bermuda',
        iso2: 'BM',
        iso3: 'BMU',
        countryCode: '1',
    },
    {
        country: 'Bhutan',
        iso2: 'BT',
        iso3: 'BTN',
        countryCode: '975',
    },
    {
        country: 'Bolivia',
        iso2: 'BO',
        iso3: 'BOL',
        countryCode: '591',
    },
    {
        country: 'Bosnia and Herzegovina',
        iso2: 'BA',
        iso3: 'BIH',
        countryCode: '387',
    },
    {
        country: 'Botswana',
        iso2: 'BW',
        iso3: 'BWA',
        countryCode: '267',
    },
    {
        country: 'Brazil',
        iso2: 'BR',
        iso3: 'BRA',
        countryCode: '55',
    },
    {
        country: 'British Virgin Islands',
        iso2: 'VG',
        iso3: 'VGB',
        countryCode: '1',
    },
    {
        country: 'Brunei',
        iso2: 'BN',
        iso3: 'BRN',
        countryCode: '673',
    },
    {
        country: 'Bulgaria',
        iso2: 'BG',
        iso3: 'BGR',
        countryCode: '359',
    },
    {
        country: 'Burkina Faso',
        iso2: 'BF',
        iso3: 'BFA',
        countryCode: '226',
    },
    {
        country: 'Burma (Myanmar)',
        iso2: 'MM',
        iso3: 'MMR',
        countryCode: '95',
    },
    {
        country: 'Burundi',
        iso2: 'BI',
        iso3: 'BDI',
        countryCode: '257',
    },
    {
        country: 'Cambodia',
        iso2: 'KH',
        iso3: 'KHM',
        countryCode: '855',
    },
    {
        country: 'Cameroon',
        iso2: 'CM',
        iso3: 'CMR',
        countryCode: '237',
    },
    {
        country: 'Canada',
        iso2: 'CA',
        iso3: 'CAN',
        countryCode: '1',
    },
    {
        country: 'Cape Verde',
        iso2: 'CV',
        iso3: 'CPV',
        countryCode: '238',
    },
    {
        country: 'Cayman Islands',
        iso2: 'KY',
        iso3: 'CYM',
        countryCode: '1',
    },
    {
        country: 'Central African Republic',
        iso2: 'CF',
        iso3: 'CAF',
        countryCode: '236',
    },
    {
        country: 'Chad',
        iso2: 'TD',
        iso3: 'TCD',
        countryCode: '235',
    },
    {
        country: 'Chile',
        iso2: 'CL',
        iso3: 'CHL',
        countryCode: '56',
    },
    {
        country: 'China',
        iso2: 'CN',
        iso3: 'CHN',
        countryCode: '86',
    },
    {
        country: 'Christmas Island',
        iso2: 'CX',
        iso3: 'CXR',
        countryCode: '61',
    },
    {
        country: 'Cocos (Keeling) Islands',
        iso2: 'CC',
        iso3: 'CCK',
        countryCode: '61',
    },
    {
        country: 'Colombia',
        iso2: 'CO',
        iso3: 'COL',
        countryCode: '57',
    },
    {
        country: 'Comoros',
        iso2: 'KM',
        iso3: 'COM',
        countryCode: '269',
    },
    {
        country: 'Congo',
        iso2: 'CG',
        iso3: 'COG',
        countryCode: '242',
    },
    {
        country: 'Cook Islands',
        iso2: 'CK',
        iso3: 'COK',
        countryCode: '682',
    },
    {
        country: 'Costa Rica',
        iso2: 'CR',
        iso3: 'CRC',
        countryCode: '506',
    },
    {
        country: 'Croatia',
        iso2: 'HR',
        iso3: 'HRV',
        countryCode: '385',
    },
    {
        country: 'Cuba',
        iso2: 'CU',
        iso3: 'CUB',
        countryCode: '53',
    },
    {
        country: 'Cyprus',
        iso2: 'CY',
        iso3: 'CYP',
        countryCode: '357',
    },
    {
        country: 'Czech Republic',
        iso2: 'CZ',
        iso3: 'CZE',
        countryCode: '420',
    },
    {
        country: 'Democratic Republic of the Congo',
        iso2: 'CD',
        iso3: 'COD',
        countryCode: '243',
    },
    {
        country: 'Denmark',
        iso2: 'DK',
        iso3: 'DNK',
        countryCode: '45',
    },
    {
        country: 'Diego Garcia',
        iso2: 'DG',
        iso3: 'DGA',
        countryCode: '246',
    },
    {
        country: 'Djibouti',
        iso2: 'DJ',
        iso3: 'DJI',
        countryCode: '253',
    },
    {
        country: 'Dominica',
        iso2: 'DM',
        iso3: 'DMA',
        countryCode: '1',
    },
    {
        country: 'Dominican Republic',
        iso2: 'DO',
        iso3: 'DOM',
        countryCode: '1',
    },
    {
        country: 'Ecuador',
        iso2: 'EC',
        iso3: 'ECU',
        countryCode: '593',
    },
    {
        country: 'Egypt',
        iso2: 'EG',
        iso3: 'EGY',
        countryCode: '20',
    },
    {
        country: 'El Salvador',
        iso2: 'SV',
        iso3: 'SLV',
        countryCode: '503',
    },
    {
        country: 'Equatorial Guinea',
        iso2: 'GQ',
        iso3: 'GNQ',
        countryCode: '240',
    },
    {
        country: 'Eritrea',
        iso2: 'ER',
        iso3: 'ERI',
        countryCode: '291',
    },
    {
        country: 'Estonia',
        iso2: 'EE',
        iso3: 'EST',
        countryCode: '372',
    },
    {
        country: 'Ethiopia',
        iso2: 'ET',
        iso3: 'ETH',
        countryCode: '251',
    },
    {
        country: 'Falkland Islands',
        iso2: 'FK',
        iso3: 'FLK',
        countryCode: '500',
    },
    {
        country: 'Faroe Islands',
        iso2: 'FO',
        iso3: 'FRO',
        countryCode: '298',
    },
    {
        country: 'Fiji',
        iso2: 'FJ',
        iso3: 'FJI',
        countryCode: '679',
    },
    {
        country: 'Finland',
        iso2: 'FI',
        iso3: 'FIN',
        countryCode: '358',
    },
    {
        country: 'France',
        iso2: 'FR',
        iso3: 'FRA',
        countryCode: '33',
    },
    {
        country: 'French Guiana',
        iso2: 'GF',
        iso3: 'GUF',
        countryCode: '594',
    },
    {
        country: 'French Polynesia',
        iso2: 'PF',
        iso3: 'PYF',
        countryCode: '689',
    },
    {
        country: 'Gabon',
        iso2: 'GA',
        iso3: 'GAB',
        countryCode: '241',
    },
    {
        country: 'Gambia',
        iso2: 'GM',
        iso3: 'GMB',
        countryCode: '220',
    },
    {
        country: 'Georgia',
        iso2: 'GE',
        iso3: 'GEO',
        countryCode: '995',
    },
    {
        country: 'Germany',
        iso2: 'DE',
        iso3: 'DEU',
        countryCode: '49',
    },
    {
        country: 'Ghana',
        iso2: 'GH',
        iso3: 'GHA',
        countryCode: '233',
    },
    {
        country: 'Gibraltar',
        iso2: 'GI',
        iso3: 'GIB',
        countryCode: '350',
    },
    {
        country: 'Greece',
        iso2: 'GR',
        iso3: 'GRC',
        countryCode: '30',
    },
    {
        country: 'Greenland',
        iso2: 'GL',
        iso3: 'GRL',
        countryCode: '299',
    },
    {
        country: 'Grenada',
        iso2: 'GD',
        iso3: 'GRD',
        countryCode: '1',
    },
    {
        country: 'Guadeloupe',
        iso2: 'GP',
        iso3: 'GLP',
        countryCode: '590',
    },
    {
        country: 'Guam',
        iso2: 'GU',
        iso3: 'GUM',
        countryCode: '1',
    },
    {
        country: 'Guatemala',
        iso2: 'GT',
        iso3: 'GTM',
        countryCode: '502',
    },
    {
        country: 'Guinea',
        iso2: 'GN',
        iso3: 'GIN',
        countryCode: '245',
    },
    {
        country: 'Guyana',
        iso2: 'GY',
        iso3: 'GUY',
        countryCode: '592',
    },
    {
        country: 'Haiti',
        iso2: 'HT',
        iso3: 'HTI',
        countryCode: '509',
    },
    {
        country: 'Holy See (Vatican City)',
        iso2: 'VA',
        iso3: 'VAT',
        countryCode: '39',
    },
    {
        country: 'Honduras',
        iso2: 'HN',
        iso3: 'HND',
        countryCode: '504',
    },
    {
        country: 'Hong Kong',
        iso2: 'HK',
        iso3: 'HKG',
        countryCode: '852',
    },
    {
        country: 'Hungary',
        iso2: 'HU',
        iso3: 'HUN',
        countryCode: '36',
    },
    {
        country: 'Iceland',
        iso2: 'IS',
        iso3: 'IS',
        countryCode: '354',
    },
    {
        country: 'India',
        iso2: 'IN',
        iso3: 'IND',
        countryCode: '91',
    },
    {
        country: 'Indonesia',
        iso2: 'ID',
        iso3: 'IDN',
        countryCode: '62',
    },
    {
        country: 'Iran',
        iso2: 'IR',
        iso3: 'IRN',
        countryCode: '98',
    },
    {
        country: 'Iraq',
        iso2: 'IQ',
        iso3: 'IRQ',
        countryCode: '964',
    },
    {
        country: 'Ireland',
        iso2: 'IE',
        iso3: 'IRL',
        countryCode: '353',
    },
    {
        country: 'Isle of Man',
        iso2: 'IM',
        iso3: 'IMN',
        countryCode: '44',
    },
    {
        country: 'Israel',
        iso2: 'IL',
        iso3: 'ISR',
        countryCode: '972',
    },
    {
        country: 'Italy',
        iso2: 'IT',
        iso3: 'ITA',
        countryCode: '225',
    },
    {
        country: 'Jamaica',
        iso2: 'JM',
        iso3: 'JAM',
        countryCode: '1',
    },
    {
        country: 'Japan',
        iso2: 'JP',
        iso3: 'JPN',
        countryCode: '81',
    },
    {
        country: 'Jersey',
        iso2: 'JE',
        iso3: 'JEY',
        countryCode: '44',
    },
    {
        country: 'Jordan',
        iso2: 'JO',
        iso3: 'JOR',
        countryCode: '962',
    },
    {
        country: 'Kazakhstan',
        iso2: 'KZ',
        iso3: 'KAZ',
        countryCode: '7',
    },
    {
        country: 'Kenya',
        iso2: 'KE',
        iso3: 'KEN',
        countryCode: '254',
    },
    {
        country: 'Kiribati',
        iso2: 'KI',
        iso3: 'KIR',
        countryCode: '686',
    },
    {
        country: 'Kuwait',
        iso2: 'KW',
        iso3: 'KWT',
        countryCode: '965',
    },
    {
        country: 'Kyrgyzstan',
        iso2: 'KG',
        iso3: 'KGZ',
        countryCode: '996',
    },
    {
        country: 'Laos',
        iso2: 'LA',
        iso3: 'LAO',
        countryCode: '856',
    },
    {
        country: 'Latvia',
        iso2: 'LV',
        iso3: 'LVA',
        countryCode: '371',
    },
    {
        country: 'Lebanon',
        iso2: 'LB',
        iso3: 'LBN',
        countryCode: '961',
    },
    {
        country: 'Lesotho',
        iso2: 'LS',
        iso3: 'LSO',
        countryCode: '266',
    },
    {
        country: 'Liberia',
        iso2: 'LR',
        iso3: 'LBR',
        countryCode: '231',
    },
    {
        country: 'Libya',
        iso2: 'LY',
        iso3: 'LBY',
        countryCode: '218',
    },
    {
        country: 'Liechtenstein',
        iso2: 'LI',
        iso3: 'LIE',
        countryCode: '423',
    },
    {
        country: 'Lithuania',
        iso2: 'LT',
        iso3: 'LTU',
        countryCode: '370',
    },
    {
        country: 'Luxembourg',
        iso2: 'LU',
        iso3: 'LUX',
        countryCode: '352',
    },
    {
        country: 'Macau',
        iso2: 'MO',
        iso3: 'MAC',
        countryCode: '853',
    },
    {
        country: 'Macedonia',
        iso2: 'MK',
        iso3: 'MKD',
        countryCode: '389',
    },
    {
        country: 'Madagascar',
        iso2: 'MG',
        iso3: 'MDG',
        countryCode: '261',
    },
    {
        country: 'Malawi',
        iso2: 'MW',
        iso3: 'MWI',
        countryCode: '265',
    },
    {
        country: 'Malaysia',
        iso2: 'MY',
        iso3: 'MYS',
        countryCode: '60',
    },
    {
        country: 'Maldives',
        iso2: 'MV',
        iso3: 'MDV',
        countryCode: '960',
    },
    {
        country: 'Mali',
        iso2: 'ML',
        iso3: 'MLI',
        countryCode: '223',
    },
    {
        country: 'Malta',
        iso2: 'MT',
        iso3: 'MLT',
        countryCode: '356',
    },
    {
        country: 'Marshall Islands',
        iso2: 'MH',
        iso3: 'MHL',
        countryCode: '692',
    },
    {
        country: 'Martinique',
        iso2: 'MQ',
        iso3: 'MTQ',
        countryCode: '596',
    },
    {
        country: 'Mauritania',
        iso2: 'MR',
        iso3: 'MRT',
        countryCode: '222',
    },
    {
        country: 'Mauritius',
        iso2: 'MU',
        iso3: 'MUS',
        countryCode: '230',
    },
    {
        country: 'Mayotte',
        iso2: 'YT',
        iso3: 'MYT',
        countryCode: '262',
    },
    {
        country: 'Mexico',
        iso2: 'MX',
        iso3: 'MEX',
        countryCode: '52',
    },
    {
        country: 'Micronesia',
        iso2: 'FM',
        iso3: 'FSM',
        countryCode: '691',
    },
    {
        country: 'Moldova',
        iso2: 'MD',
        iso3: 'MDA',
        countryCode: '373',
    },
    {
        country: 'Monaco',
        iso2: 'MC',
        iso3: 'MCO',
        countryCode: '377',
    },
    {
        country: 'Mongolia',
        iso2: 'MN',
        iso3: 'MNG',
        countryCode: '976',
    },
    {
        country: 'Montenegro',
        iso2: 'ME',
        iso3: 'MNE',
        countryCode: '382',
    },
    {
        country: 'Montserrat',
        iso2: 'MS',
        iso3: 'MSR',
        countryCode: '1',
    },
    {
        country: 'Morocco',
        iso2: 'MA',
        iso3: 'MAR',
        countryCode: '212',
    },
    {
        country: 'Mozambique',
        iso2: 'MZ',
        iso3: 'MOZ',
        countryCode: '258',
    },
    {
        country: 'Namibia',
        iso2: 'NA',
        iso3: 'NAM',
        countryCode: '264',
    },
    {
        country: 'Nauru',
        iso2: 'NR',
        iso3: 'NRU',
        countryCode: '674',
    },
    {
        country: 'Nepal',
        iso2: 'NP',
        iso3: 'NPL',
        countryCode: '977',
    },
    {
        country: 'Netherlands',
        iso2: 'NL',
        iso3: 'NLD',
        countryCode: '31',
    },
    {
        country: 'Netherlands Antilles',
        iso2: 'AN',
        iso3: 'ANT',
        countryCode: '599',
    },
    {
        country: 'New Caledonia',
        iso2: 'NC',
        iso3: 'NCL',
        countryCode: '687',
    },
    {
        country: 'New Zealand',
        iso2: 'NZ',
        iso3: 'NZL',
        countryCode: '64',
    },
    {
        country: 'Nicaragua',
        iso2: 'NI',
        iso3: 'NIC',
        countryCode: '505',
    },
    {
        country: 'Niger',
        iso2: 'NE',
        iso3: 'NER',
        countryCode: '227',
    },
    {
        country: 'Nigeria',
        iso2: 'NG',
        iso3: 'NGA',
        countryCode: '234',
    },
    {
        country: 'Niue',
        iso2: 'NU',
        iso3: 'NIU',
        countryCode: '683',
    },
    {
        country: 'Norfolk Island',
        iso2: 'NF',
        iso3: 'NFK',
        countryCode: '672',
    },
    {
        country: 'North Korea',
        iso2: 'KP',
        iso3: 'PRK',
        countryCode: '850',
    },
    {
        country: 'Northern Mariana Islands',
        iso2: 'MP',
        iso3: 'MNP',
        countryCode: '1',
    },
    {
        country: 'Norway',
        iso2: 'NO',
        iso3: 'NOR',
        countryCode: '47',
    },
    {
        country: 'Oman',
        iso2: 'OM',
        iso3: 'OMN',
        countryCode: '968',
    },
    {
        country: 'Pakistan',
        iso2: 'PK',
        iso3: 'PAK',
        countryCode: '92',
    },
    {
        country: 'Palau',
        iso2: 'PW',
        iso3: 'PLW',
        countryCode: '680',
    },
    {
        country: 'Palestine',
        iso2: 'PS',
        iso3: 'PSE',
        countryCode: '970',
    },
    {
        country: 'Panama',
        iso2: 'PA',
        iso3: 'PAN',
        countryCode: '507',
    },
    {
        country: 'Papua New Guinea',
        iso2: 'PG',
        iso3: 'PNG',
        countryCode: '675',
    },
    {
        country: 'Paraguay',
        iso2: 'PY',
        iso3: 'PRY',
        countryCode: '595',
    },
    {
        country: 'Peru',
        iso2: 'PE',
        iso3: 'PER',
        countryCode: '51',
    },
    {
        country: 'Philippines',
        iso2: 'PH',
        iso3: 'PHL',
        countryCode: '63',
    },
    {
        country: 'Pitcairn Islands',
        iso2: 'PN',
        iso3: 'PCN',
        countryCode: '870',
    },
    {
        country: 'Poland',
        iso2: 'PL',
        iso3: 'POL',
        countryCode: '48',
    },
    {
        country: 'Portugal',
        iso2: 'PT',
        iso3: 'PRT',
        countryCode: '351',
    },
    {
        country: 'Puerto Rico',
        iso2: 'PR',
        iso3: 'PRI',
        countryCode: '1',
    },
    {
        country: 'Qatar',
        iso2: 'QA',
        iso3: 'QAT',
        countryCode: '974',
    },
    {
        country: 'Republic of the Congo',
        iso2: 'CG',
        iso3: 'COG',
        countryCode: '242',
    },
    {
        country: 'Reunion Island',
        iso2: 'RE',
        iso3: 'REU',
        countryCode: '262',
    },
    {
        country: 'Romania',
        iso2: 'RO',
        iso3: 'ROU',
        countryCode: '40',
    },
    {
        country: 'Russia',
        iso2: 'RU',
        iso3: 'RUS',
        countryCode: '7',
    },
    {
        country: 'Rwanda',
        iso2: 'RW',
        iso3: 'RWA',
        countryCode: '250',
    },
    {
        country: 'Saint Barthelemy',
        iso2: 'BL',
        iso3: 'BLM',
        countryCode: '590',
    },
    {
        country: 'Saint Helena',
        iso2: 'SH',
        iso3: 'SHN',
        countryCode: '290',
    },
    {
        country: 'Saint Kitts and Nevis',
        iso2: 'KN',
        iso3: 'KNA',
        countryCode: '1',
    },
    {
        country: 'Saint Lucia',
        iso2: 'LC',
        iso3: 'LCA',
        countryCode: '1',
    },
    {
        country: 'Saint Martin',
        iso2: 'MF',
        iso3: 'MAF',
        countryCode: '590',
    },
    {
        country: 'Saint Pierre and Miquelon',
        iso2: 'PM',
        iso3: 'SPM',
        countryCode: '508',
    },
    {
        country: 'Saint Vincent and the Grenadines',
        iso2: 'VC',
        iso3: 'VCT',
        countryCode: '1',
    },
    {
        country: 'Samoa',
        iso2: 'WS',
        iso3: 'WSM',
        countryCode: '685',
    },
    {
        country: 'San Marino',
        iso2: 'SM',
        iso3: 'SMR',
        countryCode: '378',
    },
    {
        country: 'Sao Tome and Principe',
        iso2: 'ST',
        iso3: 'STP',
        countryCode: '239',
    },
    {
        country: 'Saudi Arabia',
        iso2: 'SA',
        iso3: 'SAU',
        countryCode: '966',
    },
    {
        country: 'Senegal',
        iso2: 'SN',
        iso3: 'SEN',
        countryCode: '221',
    },
    {
        country: 'Serbia',
        iso2: 'RS',
        iso3: 'SRB',
        countryCode: '381',
    },
    {
        country: 'Seychelles',
        iso2: 'SC',
        iso3: 'SYC',
        countryCode: '248',
    },
    {
        country: 'Sierra Leone',
        iso2: 'SL',
        iso3: 'SLE',
        countryCode: '232',
    },
    {
        country: 'Singapore',
        iso2: 'SG',
        iso3: 'SGP',
        countryCode: '65',
    },
    {
        country: 'Sint Maarten',
        iso2: 'SX',
        iso3: 'SXM',
        countryCode: '1',
    },
    {
        country: 'Slovakia',
        iso2: 'SK',
        iso3: 'SVK',
        countryCode: '421',
    },
    {
        country: 'Slovenia',
        iso2: 'SI',
        iso3: 'SVN',
        countryCode: '386',
    },
    {
        country: 'Solomon Islands',
        iso2: 'SB',
        iso3: 'SLB',
        countryCode: '677',
    },
    {
        country: 'Somalia',
        iso2: 'SO',
        iso3: 'SOM',
        countryCode: '252',
    },
    {
        country: 'South Africa',
        iso2: 'ZA',
        iso3: 'ZAF',
        countryCode: '27',
    },
    {
        country: 'South Korea',
        iso2: 'KR',
        iso3: 'KOR',
        countryCode: '82',
    },
    {
        country: 'South Sudan',
        iso2: 'SS',
        iso3: 'SSD',
        countryCode: '211',
    },
    {
        country: 'Spain',
        iso2: 'ES',
        iso3: 'ESP',
        countryCode: '34',
    },
    {
        country: 'Sri Lanka',
        iso2: 'LK',
        iso3: 'LKA',
        countryCode: '94',
    },
    {
        country: 'Sudan',
        iso2: 'SD',
        iso3: 'SDN',
        countryCode: '249',
    },
    {
        country: 'Suriname',
        iso2: 'SR',
        iso3: 'SUR',
        countryCode: '597',
    },
    {
        country: 'Svalbard',
        iso2: 'SJ',
        iso3: 'SJM',
        countryCode: '47',
    },
    {
        country: 'Swaziland',
        iso2: 'SZ',
        iso3: 'SWZ',
        countryCode: '268',
    },
    {
        country: 'Sweden',
        iso2: 'SE',
        iso3: 'SWE',
        countryCode: '46',
    },
    {
        country: 'Switzerland',
        iso2: 'CH',
        iso3: 'CHE',
        countryCode: '41',
    },
    {
        country: 'Syria',
        iso2: 'SY',
        iso3: 'SYR',
        countryCode: '963',
    },
    {
        country: 'Taiwan',
        iso2: 'TW',
        iso3: 'TWN',
        countryCode: '886',
    },
    {
        country: 'Tajikistan',
        iso2: 'TJ',
        iso3: 'TJK',
        countryCode: '992',
    },
    {
        country: 'Tanzania',
        iso2: 'TZ',
        iso3: 'TZA',
        countryCode: '255',
    },
    {
        country: 'Thailand',
        iso2: 'TH',
        iso3: 'THA',
        countryCode: '670',
    },
    {
        country: 'Togo',
        iso2: 'TG',
        iso3: 'TGO',
        countryCode: '228',
    },
    {
        country: 'Tokelau',
        iso2: 'TK',
        iso3: 'TKL',
        countryCode: '690',
    },
    {
        country: 'Tonga Islands',
        iso2: 'TO',
        iso3: 'TON',
        countryCode: '676',
    },
    {
        country: 'Trinidad and Tobago',
        iso2: 'TT',
        iso3: 'TTO',
        countryCode: '1',
    },
    {
        country: 'Tunisia',
        iso2: 'TN',
        iso3: 'TUN',
        countryCode: '216',
    },
    {
        country: 'Turkey',
        iso2: 'TR',
        iso3: 'TUR',
        countryCode: '90',
    },
    {
        country: 'Turkmenistan',
        iso2: 'TM',
        iso3: 'TKM',
        countryCode: '993',
    },
    {
        country: 'Turks and Caicos Islands',
        iso2: 'TC',
        iso3: 'TCA',
        countryCode: '1',
    },
    {
        country: 'Tuvalu',
        iso2: 'TV',
        iso3: 'TUV',
        countryCode: '688',
    },
    {
        country: 'Uganda',
        iso2: 'UG',
        iso3: 'UGA',
        countryCode: '256',
    },
    {
        country: 'Ukraine',
        iso2: 'UA',
        iso3: 'UKR',
        countryCode: '380',
    },
    {
        country: 'United Arab Emirates',
        iso2: 'AE',
        iso3: 'ARE',
        countryCode: '971',
    },
    {
        country: 'United Kingdom',
        iso2: 'GB',
        iso3: 'GBR',
        countryCode: '44',
    },
    {
        country: 'United States',
        iso2: 'US',
        iso3: 'USA',
        countryCode: '1',
    },
    {
        country: 'Uruguay',
        iso2: 'UY',
        iso3: 'URY',
        countryCode: '598',
    },
    {
        country: 'US Virgin Islands',
        iso2: 'VI',
        iso3: 'VIR',
        countryCode: '1',
    },
    {
        country: 'Uzbekistan',
        iso2: 'UZ',
        iso3: 'UZB',
        countryCode: '998',
    },
    {
        country: 'Vanuatu',
        iso2: 'VU',
        iso3: 'VUT',
        countryCode: '678',
    },
    {
        country: 'Venezuela',
        iso2: 'VE',
        iso3: 'VEN',
        countryCode: '58',
    },
    {
        country: 'Vietnam',
        iso2: 'VN',
        iso3: 'VNM',
        countryCode: '84',
    },
    {
        country: 'Wallis and Futuna',
        iso2: 'WF',
        iso3: 'WLF',
        countryCode: '681',
    },
    {
        country: 'Western Sahara',
        iso2: 'EH',
        iso3: 'ESH',
        countryCode: '212',
    },
    {
        country: 'Yemen',
        iso2: 'YE',
        iso3: 'YEM',
        countryCode: '967',
    },
    {
        country: 'Zambia',
        iso2: 'ZM',
        iso3: 'ZMB',
        countryCode: '260',
    },
    {
        country: 'Zimbabwe',
        iso2: 'ZW',
        iso3: 'ZWE',
        countryCode: '263',
    },
];
