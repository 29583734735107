import '@styles';
import { MDCSelect } from '@material/select';
import { TsxMdcComponent } from '@models';
(() => {
    const thisWindow = window;
    thisWindow.tsxMdcComponentsInitSelects = () => {
        const selects = new TsxMdcComponent('.mdc-select', MDCSelect);
    };
    thisWindow.tsxMdcComponentsInitSelects();
})();
