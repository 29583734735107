import { getHTMLElementArray } from '@utils';
/* eslint-disable @typescript-eslint/no-explicit-any */
export class TsxMdcComponent {
    constructor(elements, component) {
        const els = getHTMLElementArray(elements);
        // store a reference to each child component on the class
        this.components = createComponents(els, component);
    }
}
const createComponents = (elements, component) => {
    return elements.reduce((components, element) => {
        return [
            ...components,
            {
                nativeElement: element,
                component: new component(element),
            },
        ];
    }, []);
};
