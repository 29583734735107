export const getHTMLElementArray = (elements) => {
    if (typeof elements === 'string') {
        const typed = document.querySelectorAll(elements);
        return Array.from(typed);
    }
    else if (Object.prototype.isPrototypeOf.call(NodeList.prototype, elements)) {
        const typed = elements;
        return Array.from(typed);
    }
    else {
        const typed = elements;
        return [typed];
    }
};
