export const COUNTRY_CODES_DENIED_LIST = [
    'AFG',
    'CUB',
    'RUS',
    'PRK',
    'SYR',
    'IRN',
    'CHN',
    'LBY',
];
